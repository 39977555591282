import React, { Component } from "react"
import Logo from "./logo.png"
import "./LoginForm.css"
const Header= ()=> <div className="row" style ={{backgroundImage:  "linear-gradient(to Right, rgb(218, 232, 250),rgb(5, 74, 163))",height: "9%"}} >
    <div>  <img src ={Logo} alt ="Logo" style ={{ maxWidth:"25%",margin:"1%"}} ></img>
     </div>
        </div>



export default Header